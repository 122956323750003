<template>
  <div class="fullscreen-icon" @click="click(divId)">
    <i v-if="!isFullscreen" class="iconfont icon-fullscreen" />
    <i v-else class="iconfont icon-exit-fullscreen" />
  </div>
</template>

<script>
import screenfull from "screenfull";

export default {
  name: "Screenfull",
  props: {
    divId: {
      type: String,
      default() {
        return null;
      }
    },
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    click(divId) {
      if (!screenfull.enabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      if (divId) {
        const element = document.getElementById(divId); // 替换为你的 div 的 id
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          element.requestFullscreen();
        }
      } else {
        screenfull.toggle();
      }
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-icon {
  cursor: pointer;
  i {
    color: #5a5e66;
    font-size: 24px;
  }
}
</style>
