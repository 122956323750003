import Cookies from "js-cookie"

const TokenKey = "X-APS-Authorization"

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token, { domain: 'dhub.com.cn'})
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
