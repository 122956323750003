/** When your routing table is too long, you can split it into small modules **/

import Layout from "@/layout";

const webRouter = [
  {
    path: "/product",
    component: Layout,
    redirect: "/product/category",
    name: "Product",
    meta: {
      title: "工艺数据管理",
      icon: "el-icon-user",
    },
    children: [
      // {
      //   name: "Skill",
      //   path: "skill",
      //   component: () => import("@/views/pages/skill/index"),
      //   meta: {
      //     title: "技能组",
      //     icon: "el-icon-user",
      //   },
      // },
      {
        name: "ProcedureLibrary",
        path: "procedure-library",
        component: () => import("@/views/pages/procedure-library/index"),
        meta: {
          title: "工序库",
          icon: "el-icon-user",
        },
      },
      {
        name: "Category",
        path: "category",
        component: () => import("@/views/pages/product/category"),
        meta: {
          title: "工艺管理",
          icon: "el-icon-user",
        },
      },
      // {
      //   name: "ProcessFlow",
      //   path: "process-flow",
      //   component: () => import("@/views/pages/process-flow/index"),
      //   meta: {
      //     title: "工艺流程",
      //     icon: "el-icon-user",
      //   },
      // },
      {
        name: "BomCategory",
        path: "bomCategory",
        component: () => import("@/views/pages/bomCategory/index"),
        meta: {
          title: "bom类型设定",
          icon: "el-icon-user",
        },
      },

      // {
      //   name: "Task",
      //   path: "task",
      //   component: () => import("@/views/pages/schedule/task-list"),
      //   meta: {
      //     title: "任务显示列表",
      //     icon: "el-icon-user",
      //   },
      // },
    ],
  },

  {
    path: "/schedule",
    component: Layout,
    redirect: "/schedule/index",
    name: "Schedule",
    meta: {
      title: "智能排程",
      icon: "el-icon-s-order",
    },
    children: [
      {
        name: "Schedule",
        path: "resource-total",
        component: () => import("@/views/pages/schedule/index"),
        meta: {
          title: "智能排程",
          icon: "el-icon-user",
        },
      },
    ],
  },
];
export default webRouter;
