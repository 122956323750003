import Vue from "vue";
import App from "@/App";
import store from "@/store";
import router from "@/router";

import dataV from "@jiaminghi/data-view";
// 引入全局css
import "./assets/scss/style.scss";
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

import "@/plugins/element";
import "@/plugins/axios";
import "@/plugins/filters";
import "@/plugins/lazyload";
// import "@/plugins/jsstozip.ext";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import "@/styles/index.scss"; // global css
import "@/icons"; // icon
import "@/permission"; // permission control

// 引入echart
// 4.x 引用方式
import echarts from "echarts";

import constants from "@/utils/constants";
// 5.x 引用方式为按需引用
// 希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
// import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.GLOBAL = constants;

// 全局注册
Vue.component("Icon", Icon);
Vue.use(dataV);

Vue.prototype.CONF_OPTION = {
  title: "提示",
  closeOnClickModal: false,
  type: "warning",
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
