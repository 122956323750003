import { getToken, setToken, removeToken } from "@/utils/auth"
import router, { resetRouter } from "@/router"

const state = {
  token: getToken(),
  name: "",
  avatar: "",
  introduction: "",
  roles: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_USER_ID: (state, id) => {
    state.id = id
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
}

const actions = {
  // user login
  login ({ commit }, loginForm) {
    const { username, password, captchaId, captchaCode } = loginForm
    return new Promise((resolve, reject) => {
      this._vm.axios
        .form("/view/login/login", {
          username,
          password,
          captchaId,
          captchaCode,
        })
        .then((data) => {
          commit("SET_TOKEN", data)
          setToken(data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  async getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      this._vm.axios
        .get("/admin/mea", { headers: { "X-APS-Authorization": "Bearer " + state.token } })
        .then((data) => {
          if (!data || !data.userId || data.manager == null) {
            reject(new Error("抱歉，审核失败, 请重新登录."))
          }
          // roles must be a non-empty array
          if (!data.authorities || data.authorities.length <= 0) {
            reject(new Error("抱歉，审核失败, 请重新登录."))
          }
          commit("SET_USER_ID", data.userId)
          const authes = []
          data.authorities.map((item) => {
            authes.push(item.authority)
          })
          commit("SET_ROLES", authes)
          commit("SET_NAME", data.fullname)
          commit("SET_AVATAR", "")
          commit("SET_INTRODUCTION", "")
          data.roles = authes
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  async logout ({ commit, state, dispatch }) {
    await this._vm.axios.get("/view/login/logout");
    commit("SET_TOKEN", "")
    commit("SET_ROLES", [])
    removeToken()
    resetRouter()
    // reset visited views and cached views
    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
    // dispatch("tagsView/delAllViews", null, { root: true })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "")
      commit("SET_ROLES", [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles ({ commit, dispatch }, role) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const token = `${role}-token`

      commit("SET_TOKEN", token)
      setToken(token)

      const { roles } = await dispatch("getInfo")

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch("permission/generateRoutes", roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch("tagsView/delAllViews", null, { root: true })

      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
