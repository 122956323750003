/**
 *
 * 0-20 定义公共  前闭后开
 * 20-30 抽屉专属
 * 30-50 第一柜子专属
 * */
const NODE_TYPE = {
  category: 100,
  // 一级箱柜
  cabinet: 50,
  // 柜体
  cabinetBody: 30,
  oneBom: 0,
  twoBom: 1,
  bus: 2,
  oneLine: 3,
  twoLine: 4,
  drawerBody: 20,
  drawer: 31,
  commonMax: 19,
  drawerMax: 29,
  cabinetMax: 49,
};

export default {
  NODE_TYPE,
};
